// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-element-design-breakdown-js": () => import("./../src/pages/elementDesignBreakdown.js" /* webpackChunkName: "component---src-pages-element-design-breakdown-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-therapy-js": () => import("./../src/templates/therapy.js" /* webpackChunkName: "component---src-templates-therapy-js" */),
  "component---src-templates-workshop-js": () => import("./../src/templates/workshop.js" /* webpackChunkName: "component---src-templates-workshop-js" */)
}

