module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"ttc3","previews":false,"pages":[{"type":"Page","match":"/:uid","component":"/opt/build/repo/src/templates/page.js"},{"type":"Therapy","match":"/therapy/:uid","component":"/opt/build/repo/src/templates/therapy.js"},{"type":"Workshop","match":"/workshop/:uid","component":"/opt/build/repo/src/templates/workshop.js"}],"sharpKeys":[{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Therapy Center","short_name":"TTC","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
