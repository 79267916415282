import React, { Fragment } from 'react';
import {Helmet} from "react-helmet";
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Nav from "./nav";
import Footer from './footer';

import './layout.css';

const MainWrapper = styled.main`
	margin: 100px auto 0 auto;
	background-color: var(--background-color);
	& section:nth-child(odd) {
		background-color: var(--secondary-color);
	}
	color: var(--text-color);
	height: fit-content;
	width: 100%;
	flex-grow: 1;

	@media only screen and (max-width: 850px) {
		padding-bottom: 100px;
	}

	@media only screen and (max-width: 450px) {
		margin-top: 70px;
	}
`;

const navigationQuery = graphql`
{
  prismic {
    allNavigations {
      edges {
        node {
          company_title
					branding
					branding_image
          navigation_links {
            label
            link {
              ... on PRISMIC_Page {
                _meta {
                  uid
                }
              }
            }
          }
          social_links {
						social_media_type
						link {
							... on PRISMIC__ExternalLink {
                url
              }
            }
          }
        }
      }
    }
  }
}
`;

const Layout = ({ children }) => {
	return (
		<>
		<Helmet>
			<link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"  type='text/css'></link>
		</Helmet>
		<StaticQuery
			query={`${navigationQuery}`}
			render={(data) => {
				return (
					<Fragment>
						<Nav image={data.prismic.allNavigations.edges[0].node.branding_image} branding={data.prismic.allNavigations.edges[0].node.branding} links={data.prismic.allNavigations.edges[0].node.navigation_links}></Nav>
						<MainWrapper>{children}</MainWrapper>
						<Footer
							company={data.prismic.allNavigations.edges[0].node.company_title}
							socialMedias={data.prismic.allNavigations.edges[0].node.social_links}
							/>
					</Fragment>
				);
			}}
			/>
			</>
	);
};

Layout.propTypes = {
	children : PropTypes.node.isRequired
};

export default Layout;
