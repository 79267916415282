import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import NavLinks from './navLinks';
import MobNav from './bottomMobNav';

const NavWrapper = styled.header`
	position: fixed;
	padding: 0;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	background-color: var(--secondary-color);
	height: 100px;
	box-sizing: border-box;
	z-index: 30;
	box-shadow: 0px 11px 7px 0px rgba(0, 0, 0, 0.16);

	@media only screen and (max-width: 450px) {
		height: 70px;
		justify-content: flex-start;
	}
`;

const Branding = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 30;
	margin: auto 0;

	a:last-of-type {
		border-bottom: 3px solid var(--primary-color);
		letter-spacing: 3px;
		font-weight: 400;
		font-size: 2rem;
		font-family: time new roman;
		text-shadow: 0.8px 0px 0px black, -0.8px 0px 0px black, 0px 0.8px 0px black,
			0px -0.8px 0px black;

		@media only screen and (max-width: 950px) {
			font-size: 1.5rem;
			text-shadow: 0.5px 0px 0px black, -0.5px 0px 0px black, 0px 0.5px 0px black,
				0px -0.5px 0px black;
			letter-spacing: 1.5px;
			border-bottom: 2px solid var(--primary-color);
		}

		@media only screen and (max-width: 450px) {
			font-size: 1.3rem;
		}

		@media only screen and (max-width: 350px) {
			font-size: 1rem;
		}
	}

	a {
		color: var(--primary-color);
		font-weight: bold;
		text-decoration: none;
		padding-bottom: 5px;

		img {
			margin-right: 10px !important;
			width: 80px;
			height: 80px;
			margin: 0;

			@media only screen and (max-width: 450px) {
				height: 50px;
				margin-right: 0 !important;
			}
		}
	}
`;

// const NavLinks = styled.div`
// 	margin-bottom: 10px;
// 	display: flex;
// 	align-self: flex-end;
// 	a {
// 		color: var(--text-color);
// 		padding: 0 16px;
// 		text-decoration: none;
// 		font-weight: bold;
// 		font-size: 22px;

// 		&:hover {
// 			color: var(--primary-color);
// 		}
// 	}
// `;

// const NavLink = styled.div`margin: auto 0;`;

const Nav = ({ image, branding, links, blog }) => {
	const [ size, setSize ] = useState(
		typeof window !== 'undefined' ? window.innerWidth : 1000
	);

	const handleSizeChange = (e) => {
		setSize((state) => e.target.innerWidth);
	};

	useEffect(
		() => {
			const listener = (e) => handleSizeChange(e);
			window.addEventListener('resize', listener);
			return function() {
				window.removeEventListener('resize', listener);
			};
		},
		[ size ]
	);

	useEffect(() => {
		setSize(window.innerWidth);
	}, []);

	console.log(size);

	return (
		<NavWrapper>
			<Branding>
				<Link to="/">{!!image && <img src={image.url} alt="img" />}</Link>
				<Link to="/">{branding}</Link>
			</Branding>
			{size < 850 ? (
				<MobNav links={links} blog={blog} />
			) : (
				<NavLinks links={links} />
			)}
		</NavWrapper>
	);
};

export default Nav;
