import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const NavLinksWrapper = styled.ul`
	margin-bottom: 10px;
	display: flex;
	align-self: flex-end;
	list-style-type: none;

	a {
		color: var(--text-color);
		padding: 0 16px;
		text-decoration: none;
		font-weight: bold;
		font-size: 22px;

		&:hover {
			color: var(--primary-color);
		}
	}
`;

const NavLink = styled.li`margin: auto 0;`;

const NavLinks = ({ links }) => {
	return (
		<NavLinksWrapper key={'NavLinksWrapper'}>
			{links.map((link) => {
				return (
					<NavLink key={'NavLink' + link.link._meta.uid}>
						<Link to={`/${link.link._meta.uid}`}>{link.label}</Link>
					</NavLink>
				);
			})}
		</NavLinksWrapper>
	);
};

export default NavLinks;
