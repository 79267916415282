import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const MobNavWrapper = styled.div`
	display: flex;
	pointer-events: none;
	flex-direction: column;
	justify-content: space-between;
	position: fixed;
	width: 100%;
	height: 90vh;
	top: 90px;
	left: 0;

	.menu {
		pointer-events: all;
		position: absolute;
		top: 0;
		padding-top: 10px;
		width: 100%;
		height: 100%;
		background-color: rgba(235, 235, 235, 1);
		flex-grow: 1;
		z-index: 20;

		transition: left 0.1s linear;
	}

	.icons {
		pointer-events: all;
		width: 100%;
		height: 80px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: fixed;
		bottom: 0;
		left: 0;
		background-color: white;
		box-shadow: 0px -11px 7px 0px rgba(0, 0, 0, 0.16);
		z-index: 30;
		padding: 0 30px;

		@media only screen and (max-width: 450px) {
			padding: 0;
		}
	}

	i {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 80px;
		height: 100%;
		text-align: center;
		color: var(--text-color);
	}

	@media only screen and (max-width: 450px) {
		height: 80vh;
		top: 72px;
	}
`;

const MenuWrapper = styled.ul`
	list-style-type: none;
	width: 100%;
	height: 100%;
	margin: 0;

	li {
		background-color: white;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		font-size: 1.5rem;
		color: var(--text-color);
	}

	a {
		text-decoration: none;
	}
`;

const BottomMobNav = ({ links, socialMedia, contact }) => {
	const [ open, setOpen ] = useState(false);
	const newLinks = [ { label: 'Home', link: { _meta: { uid: '/' } } }, ...links ];
	return (
		<MobNavWrapper key={'bottomMobNavWrapper'}>
			<div className="menu" style={{ left: open ? '0' : '-150%' }}>
				<MenuWrapper>
					{newLinks.map((e, i) => {
						if (e.label.toLowerCase() !== 'contact') {
							return (
								<Link to={`/${e.link._meta.uid}`} key={'mobNav' + i}>
									<li
										style={{ height: `${75 / links.length}%` }}
										onClick={() => setOpen((state) => !state)}>
										{e.label}
									</li>
								</Link>
							);
						}
					})}
				</MenuWrapper>
			</div>
			<div className="icons">
				<i
					className={`fa ${open ? 'fa-close' : 'fa-bars'} fa-2x`}
					onClick={() => setOpen((state) => !state)}
				/>
				<a href="https://www.google.com">
					<i
						className="fa fa-facebook-official fa-2x"
						onClick={() => setOpen((state) => false)}
					/>
				</a>
				<Link to="/contact">
					<i
						className="fa fa-envelope fa-2x"
						onClick={() => setOpen((state) => false)}
					/>
				</Link>
			</div>
		</MobNavWrapper>
	);
};

export default BottomMobNav;
