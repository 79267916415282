import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
	background-color: var(--secondary-color);
	width: 100%;
	height: fit-content;
	display: grid;
	grid-gap: 0.5em;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	justify-content: center;
	justify-items: center;
	align-items: center;
	padding: 10px 0;
	font-size: 20px;
	color: var(--text-color);

	p {
		margin: 0;
		font-size: 22px;
	}

	a {
		color: var(--primary-text-color);
		text-decoration: none;
	}

	@media only screen and (max-width: 850px) {
		display: none;
	}
`;

const SocialMediaWrapper = styled.ul`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;

	li {
		margin: 0;
		list-style: none;
		color: var(--primary-text-color);

		a {
			width: 100%;
			height: 100%;

			.fa {
				color: var(--primary-text-color);
			}
		}
	}
`;

const Footer = ({ socialMedias, company }) => {
	const getIcon = (label) => {
		switch (label) {
			case 'twitter':
				return 'fa-twitter-square';
			case 'facebook':
				return 'fa-facebook-official';
			case 'instagram':
				return 'fa-instagram';
			case 'pinterest':
				return 'fa-pinterest-square';
			case 'linkedIn':
				return 'fa-linkedin-square';
			default: {
				return 'fa-exclamation-triangle';
			}
		}
	};
	return (
		<FooterWrapper>
			<Link to="/privacy">Privacy Policy</Link>
			<p>
				&copy;{company} {new Date().getFullYear()}
			</p>
			{!!socialMedias && (
				<SocialMediaWrapper>
					{socialMedias.map((item, i) => (
						<li key={`socialMedia-${i}`}>
							<a href={item.link.url}>
								<i
									className={`fa ${getIcon(
										item.social_media_type
									)} fa-2x`}
								/>
							</a>
						</li>
					))}
				</SocialMediaWrapper>
			)}
		</FooterWrapper>
	);
};

export default Footer;
